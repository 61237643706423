'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { Env } from '@/libs/Env';

if (typeof window !== 'undefined') {
  posthog.init(Env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    ui_host: 'https://us.posthog.com',
    person_profiles: 'identified_only',
  });
}
export function CSPostHogProvider({ children }: any) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
